<template>
  <div class="hello container mt-3 mb-5">
    <h4>Hello World Dropper</h4>

    <div class="bg-light border-sm p-3">
      
      <h5 class="lead mt-3">Documents
        <b-button variant="secondary" size="sm" @click="addItem('document')">Add Include</b-button>
      </h5>

      <div class="docs">
        <div 
          v-for="item in rootList('document')"
          :key="item.line_id"
          class="p-2 border border-secondary mb-2"

          drop-zone
          @drop="onDrop($event, item.line_id)"
          @dragover.prevent
          @dragenter.prevent
        >
          <div class="d-flex align-items-center">
            <b-img src="https://www.fillmurray.com/50/70" fluid></b-img>
            <span class="ml-2">{{ item.text }}</span>
          </div>

          <div class="px-2 pt-1" v-if="childrenOfParent(item.line_id).length > 0">
            <div 
              v-for="child in childrenOfParent(item.line_id)"
              :key="child.line_id"
              class="draggable my-1 d-inline-block mx-1"
              draggable
              @dragstart="startDrag($event, child)"
            >
              {{ child.text }}
            </div>
          </div>
        </div>
      </div>

      <h5 class="lead mt-3">Includes
        <b-button variant="secondary" size="sm" @click="addItem('include')">Add Include</b-button>
      </h5>

      <!-- includes zone -->
      <div class="includes">
        <div 
          class="drop-zone p-2 border border-info"
          @drop="onDrop($event, null)"
          @dragover.prevent
          @dragenter.prevent
          >
          <div 
            v-for="include in rootList('include')"
            :key="include.line_id"
            class="draggable my-1 d-inline-block mx-1"
            draggable
            @dragstart="startDrag($event, include)"
          >
            {{ include.text }}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {},
  data() {
    return {
      items: [
        {line_id: 1, type: "document", text: "Document 1", parent_line_id: null, order: 0 },
        {line_id: 2, type: "include", text: "Include 1", parent_line_id: 1, order: 0 },
        {line_id: 3, type: "document", text: "Document 2", parent_line_id: null, order: 0 },
        {line_id: 4, type: "document", text: "Document 3", parent_line_id: null, order: 0 },
        {line_id: 5, type: "include", text: "Include 2", parent_line_id: null, order: 0 },
      ]
    };
  },
  computed: {
    sorted_items() { // Overkill
      const items = JSON.parse(JSON.stringify(this.items));
      function compare(a, b) {
        if (a?.order < b?.order) return -1;
        if (a?.order > b?.order) return 1;
        return 0;
      }
      return items.sort(compare);
    }
  },
  methods: {
    rootList( type ) {
      return this.sorted_items.filter(x => x.parent_line_id == null && x.type == type );
    },
    childrenOfParent( parent_line_id ) {
      return this.sorted_items.filter(x => x.parent_line_id == parent_line_id );
    },

    addItem(type) {
      let new_line_id = this.items.reduce((max, current) => max > current.line_id ? max : current.line_id, 0) + 1;
      this.items.push({line_id: new_line_id, type: type, text: `New ${type} ${new_line_id}`, parent_line_id: null, order: Date.now()});
    },

    startDrag (evt, item) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', item.line_id)
    },
    onDrop (evt, parent_line_id) {
      console.log("dropped");
      const itemID = evt.dataTransfer.getData('itemID')
      const item = this.items.find(x => x.line_id == itemID)
      item.parent_line_id = parent_line_id
      item.order = Date.now()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.draggable {
  background: #bad0fa;
  border-radius: 4px;
  border: 1px solid #dadada;
  padding: 0.5rem;
}
.drop-zone:drop(active) {
  background: red;
}
</style>
