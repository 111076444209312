<template>
  <div class="page-faq">

    <div class="py-4 mb-4 gradient-site">
      <div class="container-fluid px-5">
        <h1 class="text-4xl font-bold mb-2 text-white">API Dashboard</h1>
        <h5 class="text-2xl mb-8 text-light">Frequently asked questions</h5>
      </div>
    </div>
  
    <!--
    <div class="container mt-5">
      <h2>Example 1</h2>

      <div class="row">
        <div class="col-lg-4 mb-3">
          <b-list-group class="rounded-0">
            <b-list-group-item 
              v-for="cat in categories" 
              button
              squared
              :variant="cat.id == active_category ? 'dark': null"
              @click="setCategory(cat.id)"
              :key="`faq-cat-${cat.id}`"
            >{{ cat.label }}</b-list-group-item>
          </b-list-group>
        </div>

        <div class="col-lg-8">
          <b-card :no-body="true" class="rounded-0">
            <transition name="fade">
              <b-card-body v-if="active_category">
                <h4 class="mb-3">{{ categories.find(x => x.id == active_category).label }}</h4>
                <div v-for="(faq, index) in faqsInCategory(active_category)" class="mb-2" :key="`faq-question-${index}`">
                  <div class="question" :class="{open: open_questions.includes(index) }">
                    <b-button 
                      block
                      squared
                      variant="secondary"
                      class="answer-toggle-button d-flex flex-sm-row justify-content-sm-between"
                      @click="toggleQuestionOpen(index)"
                    >
                      <span>{{ faq.question }}</span>
                      <b-icon :icon="open_questions.includes(index) ? 'chevron-up' : 'chevron-down'"></b-icon>
                    </b-button>
                    <div class="answer border border-top-0 mb-3 px-2 py-3" v-html="faq.answer"></div>
                  </div>
                </div>
              </b-card-body>
            </transition>
          </b-card>
        </div>

      </div>
    </div>
      
    <hr>
    <h2>Example 2</h2>
    -->

    <div class="container faqs py-4 mb-5 bg-white border rounded-0">

      <div class="row">
        <div class="col-sm-12">
          <h2>We all have questions sometimes</h2>
          <p class="mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit vero hic corporis vel excepturi, aspernatur itaque necessitatibus dolore eveniet reiciendis quisquam laudantium officiis consequuntur at non voluptate quam minus, quae fugit cumque fugiat voluptatum. Error, facere asperiores! Exercitationem assumenda dolorum consectetur! Cupiditate accusamus eius accusantium quisquam iusto pariatur officiis voluptatibus.</p>
        </div>
      </div>
          
      <b-row class="small-gutters mb-3">
        <div
          v-for="category in categories"
          :key="`cat-${category.id}`" 
          class="col-6 col-lg-4 mb-2"
          @click="setCategory(category.id)"
        >
          <div
            class="category d-flex d-lg-block border text-lg-center p-2 p-lg-4 align-items-center"
            :class="{'bg-dark text-white': category.id == active_category, 'bg-light text-dark': category.id != active_category }">
            <b-icon :icon="category.icon" class="category-icon mx-lg-auto"/>
            <div class="category-label pl-2 pl-lg-0 text-truncate">{{ category.label }}</div>
          </div>
        </div>
      </b-row>

      <b-row v-if="! active_category">
        <b-col>
          <p class="lead text-center">Please select a category above</p>
        </b-col>
      </b-row>
      
      <b-row>
        <b-col>
          <ul v-for="(faq, faq_index) in faqsInCategory(active_category)" :key="`cat-q-${faq_index}`">
            <li>
              <b>{{ faq.question }}</b>
              <div v-html="faq.answer"></div>
            </li>
          </ul>
        </b-col>
      </b-row>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      active_category: '',
      open_questions: [],

      categories: [
        {id: 'test', icon: 'cart', label: 'Using the dashboard'},
        {id: 'test2', icon: 'truck', label: 'Contact and Support'},
        {id: 'test3', icon: 'box', label: 'Some other stuff'},
        {id: 'test4', icon: 'credit-card', label: 'Some empty stuff'},
        {id: 'test5', icon: 'chat', label: 'Some other empty stuff'},
      ],

      faqs: [
        {category: 'test', question: 'Lorem ipsum dolor sit.', answer: 'Lorem ipsum dolor sit amet consectetur.'},
        {category: 'test', question: 'Lorem ipsum dolor sit amet.', answer: 'Lorem ipsum dolor sit amet consectetur.'},
        {category: 'test2', question: 'Lorem ipsum dolor sit amet consectetur.', answer: 'Lorem ipsum dolor sit amet consectetur.'},
        {category: 'test2', question: 'Lorem ipsum dolor sit amet.', answer: 'Lorem ipsum dolor sit amet consectetur.'},
        {category: 'test3', question: 'Lorem ipsum dolor.', answer: 'Lorem ipsum dolor sit <i>amet</i> consectetur.'},
      ]
    }
  },
  computed: {},
  methods: {
    setCategory(id) {
      this.active_category = null
      this.open_questions = []
      //setTimeout(() => {
        this.active_category = id
      //}, 300)
    },
    toggleQuestionOpen(index) {
      if ( this.open_questions.includes(index) ) {
        this.open_questions = this.open_questions.filter( x => x !== index)
      } else {
        this.open_questions.push(index)
      }
    },
    faqsInCategory( cat ) {
      return this.faqs.filter(x => x.category == cat)
    },
  },
};
</script>

<style lang="scss" scoped>
.question .answer {
  display: none;
}
.question.open .answer {
  display: block;
}

.row.small-gutters {
  margin-right: -5px;
  margin-left: -5px;
  & > .col, & > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

/* VERSION 2 */
.category {
  cursor: pointer;
}

li { 
  font-size: 1.1rem;
  & > div {
    font-size: 0.9rem;
  }
}

@media (min-width: 992px) {
  .category { font-size: 1.2em; }
  svg { font-size: 2.4rem; }
}
/* END VERSION 2 */

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>